import {
    loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest, SetForgotPasswordScreenFlow,
    setAuthIdOTP,
    setResultData
} from "../ActionCreators/authenticationActionCreators"
import { SET_GENERIC_ERROR_ALERT, TOGGLE_PROCESS_LOADER } from "../ActionTypes/alertsActionTypes"
import { LOGIN_INIT } from "../ActionTypes/authenticationActionTypes"
import { toggleGlobalAlert } from "../ActionCreators/alertsActionCreators";
import { SET_CHANGE_PASSWORD_SCREEN_FLOW, CLEAR_CHANGE_PASSWORD_SCREEN_FLOW } from "../ActionTypes/authenticationActionTypes"

import axios from "axios"

//Login 
export const forgotPassOTPSend = (email) => async dispatch => {


    try {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } })
        let url = process.env.REACT_APP_API_URL + "/login/forgot_password/"
        var bodyFormData = new FormData();
        bodyFormData.append("email", email)



        let result = await axios.post(url, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })

        console.log(result, "result")
        if (result.data) {

            dispatch({ type: SET_CHANGE_PASSWORD_SCREEN_FLOW })
            dispatch(toggleGlobalAlert("success", result.data, true))
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })


            // dispatch(SetForgotPasswordScreenFlow({ forgetPasswordflow: 1 }))
            // dispatch(setAuthIdOTP({ authIdOTP: result.data.authId }))
            //dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })

        }
        else {
            dispatch({ type: SET_GENERIC_ERROR_ALERT })
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })

        }

        //dispatch(loadUser())

    }
    catch (err) {
        if (err.response.status === 401) {
            dispatch(toggleGlobalAlert("warning", err.response && err.response.data
                && err.response.data.message ? err.response.data.message : "Something went wrong", true));
        }

        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })



    }
}

