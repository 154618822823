import { loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest } from "../ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../ActionTypes/authenticationActionTypes"
import axios from "axios"
import { TOGGLE_PROCESS_LOADER } from "../ActionTypes/alertsActionTypes"
import { toggleGlobalAlert } from "../ActionCreators/alertsActionCreators";

//Login 
export const login = (username, password) => async dispatch => {


    try {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } })
        dispatch({ type: LOGIN_INIT })
        let url = process.env.REACT_APP_API_URL + "/login/user_login/"
        var bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)


        let result = await axios.post(url, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })

        console.log(result, "result")
        if (result.data && result.data.id_token) {

            localStorage.setItem("userType", "user")
            if (result.data.data) {
                dispatch(loginRequest({
                    token: result.data.id_token, user: result.data.data.email,
                    userStatus: result.data.data.registration_status, profile: result.data.data,
                    userType: result.data.data.user_type
                }))

                dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })

            }
            else {
                dispatch(loginRequest({ token: result.data.id_token, user: result.data.email, userStatus: result.data.user_status }))
                dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
            }

        }
        else {
            dispatch(setLoginFailAlert())
            dispatch(loginFailureRequest())
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
        }

        //dispatch(loadUser())

    }
    catch (err) {
        dispatch(toggleGlobalAlert("warning", err.response && err.response.data
            && err.response.data.message ? err.response.data.message : "Something went wrong", true));
        dispatch(setLoginFailAlert())
        dispatch(loginFailureRequest())
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
    }
}

//Login
// export const loadUser=(email,password)=>async dispatch=>{

//     try{
//         const result=await new Promise( //   emulating api call
//          (res,rej)=>{
//              setTimeout(()=>{
//                     res({userName:"John Doe"})
//              },4000)
//          }
//         )
//         dispatch(loadUserRequest(result))

//     }
//     catch(err){
//         dispatch(authErrorRequest())
//     }
// }