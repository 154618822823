export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_INIT = "LOGIN_INIT"
export const LOGIN_REFRESH = "LOGIN_REFRESH"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const INITIAL_LOADING_STOP = "INITIAL_LOADING_STOP"

export const ACCOUNT_DELETED = "ACCOUNT_DELETED"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const CREATE_PROFILE = "CREATE_PROFILE"
export const DISABLE_CREATE_PROFILE_SCREEN = "DISABLE_CREATE_PROFILE_SCREEN"
export const SET_STATUS_PROFILE_COMPLETE = "SET_STATUS_PROFILE_COMPLETE"
export const SET_FORGOTPASS_SCREENS_FLOW = "SET_FORGOTPASS_SCREENS_FLOW"
export const CLEAR_FORGOTPASS_SCREENS_FLOW = "CLEAR_FORGOTPASS_SCREENS_FLOW"
export const SET_AUTH_ID_OTP = "SET_AUTH_ID_OTP"
export const CLEAR_AUTH_ID_OTP = "CLEAR_AUTH_ID_OTP"

export const SET_CHANGE_PASSWORD_SCREEN_FLOW = "SET_CHANGE_PASSWORD_SCREEN_FLOW"
export const CLEAR_CHANGE_PASSWORD_SCREEN_FLOW = "CLEAR_CHANGE_PASSWORD_SCREEN_FLOW"
export const SIGNUP_OTP_SCREEN_TOGGLE = "SIGNUP_OTP_SCREEN_TOGGLE"
export const SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS = "SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS"
export const set_result_data = "set_result_data"
