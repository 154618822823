import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Select, MenuItem, Button, Grid, Box, Typography } from '@mui/material';
import { useFormik } from "formik"
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Link as MUIlink } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';


const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),

});

const ForgotPassInitForm = ({ setEmail, forgotPassOTPSend, processLoader }) => {

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            setEmail(values.email)
            forgotPassOTPSend(values.email)
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ marginTop: 2 }} variant="h4" align="left">
                            Hi There!
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ marginTop: 2, marginBottom: 2, fontWeight: 600, color: "#434343" }} variant="h3" align="left">
                            Type the email you used to sign up and we'll send you an Link.
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel><Typography variant="h6"> Email address</Typography></InputLabel>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                                id="email"
                                name="email"
                                value={formik.values.email}
                                placeholder
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <LoadingButton style={{ textTransform: 'none' }} loading={processLoader} loadingPosition="center" color="primary" variant="contained" fullWidth type="submit">
                            Send Link on this email
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={12}>

                    </Grid>
                </Grid>


            </form>
        </>
    )
}

export default ForgotPassInitForm