import { signupRequest } from "../ActionCreators/authenticationActionCreators";
import { toggleGlobalAlert } from "../ActionCreators/alertsActionCreators";
import axios from "axios";
import {
  SET_SIGNUP_EXISTS_ALERT,
  SET_FORGOT_PASS_ALERT,
  TOGGLE_PROCESS_LOADER,
} from "../ActionTypes/alertsActionTypes";
import { SIGNUP_OTP_SCREEN_TOGGLE, SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS } from "../ActionTypes/authenticationActionTypes";
//Login
export const signupInit = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } });
    let url = process.env.REACT_APP_API_URL + "/login/verify_email/";
    // let url2 = process.env.REACT_APP_API_URL+"/login/user_login/";

    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("password", password);

    let result = await axios.post(url, bodyFormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (result.data.message) {
      if (result.data.message.includes("Exists")) {
        dispatch({ type: SET_SIGNUP_EXISTS_ALERT });
        dispatch({
          type: TOGGLE_PROCESS_LOADER,
          payload: { processLoader: false },
        });
      } else {
        dispatch(toggleGlobalAlert("success", result.data.message, true))
        // dispatch({ type: SIGNUP_OTP_SCREEN_TOGGLE, payload: true })
        // dispatch({ type: SET_SIGNUP_OTP_AUTH_ID_TEMP_PASS, payload: { authId: result.data.authId, tempPass: password } })
        dispatch({
          type: TOGGLE_PROCESS_LOADER,
          payload: { processLoader: false },
        });
      }
    } else {
      dispatch({ type: SET_FORGOT_PASS_ALERT });
      dispatch({
        type: TOGGLE_PROCESS_LOADER,
        payload: { processLoader: false },
      });
    }
  } catch (error) {

    if (error.response) {
      // The API returned a response (status code other than 2xx)
      dispatch(toggleGlobalAlert("warning", error.response && error.response.data
        && error.response.data.message ? error.response.data.message : "Something went wrong", true));
      console.log("Error response:", error.response.data);
      console.log("Error status:", error.response.status);
      console.log("Error headers:", error.response.headers);
    } else if (error.request) {
      // The request was made, but no response was received
      console.log("No response received:", error.request);
    } else {
      // Something else happened while setting up the request
      console.log("Error message:", error.message);
    }

    dispatch({
      type: TOGGLE_PROCESS_LOADER,
      payload: { processLoader: false },
    });
  }
};
