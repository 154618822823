import {
    loginRequest, loginFailureRequest, loadUserRequest, authErrorRequest, SetForgotPasswordScreenFlow,
    setAuthIdOTP
} from "../ActionCreators/authenticationActionCreators"
import { setLoginFailAlert } from "../ActionCreators/alertsActionCreators"
import { LOGIN_INIT } from "../ActionTypes/authenticationActionTypes"
import { SET_FORGOT_PASS_ALERT, TOGGLE_PROCESS_LOADER, SET_GENERIC_ERROR_ALERT } from "../ActionTypes/alertsActionTypes"
import axios from "axios"
import { SET_CHANGE_PASSWORD_SCREEN_FLOW, CLEAR_CHANGE_PASSWORD_SCREEN_FLOW } from "../ActionTypes/authenticationActionTypes"
import { toggleGlobalAlert } from "../ActionCreators/alertsActionCreators";
//Login 
export const changeResetPassword = (email, old_password, new_password) => async dispatch => {


    try {
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: true } })
        let url = process.env.REACT_APP_API_URL + "/login/reset_password/"
        var bodyFormData = new FormData();
        bodyFormData.append("email", email)
        bodyFormData.append("old_password", old_password)
        bodyFormData.append("new_password", new_password)



        let result = await axios.post(url, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })

        if (result.data) {

            dispatch({ type: SET_CHANGE_PASSWORD_SCREEN_FLOW })
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
            dispatch(toggleGlobalAlert("success", "Password changed successfully", true))

        }
        else {
            dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
            dispatch({ type: SET_GENERIC_ERROR_ALERT })

        }


    }
    catch (err) {

        if (err.response.status !== 200) {
            dispatch(toggleGlobalAlert("warning", err.response && err.response.data
                && err.response.data.message ? err.response.data.message : "Something went wrong", true));
        }
        dispatch({ type: TOGGLE_PROCESS_LOADER, payload: { processLoader: false } })
        dispatch({ type: SET_GENERIC_ERROR_ALERT })




    }
}

